import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['item'];

  updateActiveItem(event) {
    this.itemTargets.forEach(item => {
      const li = item.querySelector('li');
      li.classList.remove('active');
      this.removeClasses(li, item.dataset.activeClass);
      this.addClasses(li, item.dataset.inactiveClass);
    });

    const activeItem = event.currentTarget;
    const activeLi = activeItem.querySelector('li');
    activeLi.classList.add('active');
    this.removeClasses(activeLi, activeItem.dataset.inactiveClass);
    this.addClasses(activeLi, activeItem.dataset.activeClass);
  }

  removeClasses(element, classString) {
    classString.split(' ').forEach(className => {
      element.classList.remove(className);
    });
  }

  addClasses(element, classString) {
    classString.split(' ').forEach(className => {
      element.classList.add(className);
    });
  }
}
