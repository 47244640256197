class YouTubeAPIManager {
  constructor() {
    this.ready = false;
    window.onYouTubeIframeAPIReady = this.onReady.bind(this);
  }

  onReady() {
    this.ready = true;
    if (this.callback) callback();
  }

  register(callback) {
    if (this.ready) {
      callback();
    } else {
      this.callback = callback;
    }
  }
}

window.youTubeAPIManager = new YouTubeAPIManager();
