class PlayerController {
  constructor() {
    this.activeTrack = null;
    this.activeTrackIndex = null;
    this.isPlayerVisible = false;
    this.isPlaying = false;
    this.trackList = null;
    this.initializeYouTubePlayer();
  }

  initializeYouTubePlayer() {
    this.player = new YT.Player('youtube-player', {
      height: '100%',
      width: '100%',
      events: {
        'onReady': this.bind.bind(this),
        'onStateChange': this.onPlayerStateChange.bind(this)
      },
      playerVars: {
        rel: 0,
        controls: 1
      }
    });
  }

  bind() {
    this.$headerPlayButton = $('#play-button');
    this.$section = $('#era_section');
    this.$playerPlayButton = $('#player-play-button');
    this.$headerPlayButton.on('click', this.onHeaderPlayButtonClick.bind(this));
    this.$playerPlayButton.on('click', this.onPlayerPlayButtonClick.bind(this));
    this.$section.on('click', 'table tbody tr', this.onTrackClick.bind(this));
    $('#era_section').on('turbo:frame-load', this.onSectionLoad.bind(this));
  }

  onHeaderPlayButtonClick() {
    if (this.isVisibleTrackListActive()) {
      this.togglePlayPause();
    } else {
      this.playVisibleTrackList(0);
    }
  }

  onTrackClick(event) {
    const index = parseInt($(event.currentTarget).data('index'));
    if (!this.isVisibleTrackListActive()) {
      this.playVisibleTrackList(index);
      return;
    }

    if (index === this.activeTrackIndex) {
      this.togglePlayPause();
    } else {
      this.play(index);
    }
  }

  onPlayerPlayButtonClick() {
    if (this.trackList) {
      this.togglePlayPause();
    } else {
      this.playVisibleTrackList(0);
    }
  }

  playVisibleTrackList(index) {
    this.trackList = window.visibleTrackList;
    this.play(index);
  }

  togglePlayPause() {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  play(newTrackIndex) {
    const wasPlaying = this.isPlaying;
    this.isPlaying = true;
    if (newTrackIndex !== undefined) {
      this.setActiveTrack(newTrackIndex);
      if (wasPlaying) this.changingTrack = true;
      this.player.loadVideoById(this.activeTrack.youtube_video_id);
    } else {
      this.player.playVideo();
    }
    this.$playerPlayButton.addClass('playing');
    if (this.isVisibleTrackListActive()) {
      this.setTrackListPlaying(true);
    }
  }

  setTrackListPlaying(value) {
    if (value) {
      this.ensurePlayerIsVisible();
      this.$headerPlayButton.addClass('playing');
      this.getActiveTrackRow().addClass('playing');
    } else {
      this.$headerPlayButton.removeClass('playing');
      this.getActiveTrackRow().removeClass('playing');
    }
  }

  pause() {
    this.processStop();
    this.player.pauseVideo();
  }

  processStop() {
    this.isPlaying = false;
    this.$playerPlayButton.removeClass('playing');
    this.setTrackListPlaying(false);
  }

  onSectionLoad() {
    this.setTrackListPlaying(false);
  }

  onPlayerStateChange(event) {
    // When calling loadVideoById to change the track, the player will fire
    // a pause state change event after our code completes. We want to ignore
    // that event, i.e., not treat it as a user invoked pause. The player will
    // also fire a play event, which will be ignored in the case statement.
    if (this.changingTrack && event.data === YT.PlayerState.PAUSED) {
      this.changingTrack = false;
      return;
    }

    switch(event.data) {
      case YT.PlayerState.PLAYING: // 1
        if (!this.isPlaying) {
          this.play();
        }
        break;
      case YT.PlayerState.PAUSED: // 2
        if (this.isPlaying) {
          this.pause();
        }
        break;
      case YT.PlayerState.ENDED: // 0
        this.processEnded();
        break;
    }
  }

  processEnded() {
    const tracks = this.trackList.tracks.length;
    const trackNumber = this.activeTrackIndex + 1;
    if (trackNumber < tracks) {
      this.play(this.activeTrackIndex + 1);
    } else {
      this.processStop();
      this.setActiveTrack(0);
      this.player.cueVideoById(this.activeTrack.youtube_video_id);
    }
  }

  setActiveTrack(index) {
    this.getActiveTrackRow().removeClass('playing active');
    this.activeTrack = this.trackList.tracks[index];
    this.activeTrackIndex = index;
    this.getActiveTrackRow().addClass('active');
  }

  isVisibleTrackListActive() {
    return this.trackList?.id === window.visibleTrackList.id;
  }

  ensurePlayerIsVisible() {
    if (this.isPlayerVisible === false) {
      $('#album-poster').hide();
      $('#youtube-player').show();
      this.isPlayerVisible = true;
    }
  }

  getActiveTrackRow() {
    if (this.isVisibleTrackListActive()) {
      return this.$section.find(`tr[data-index=${this.activeTrackIndex}]`);
    }
  }
}

window.PlayerController = PlayerController;
